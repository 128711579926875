const BeInfo = () => {
  return (
    <div className="py-10 px-5">
      <div className="grid grid-cols-2">
        <div>상호</div>
        <div> 위킥</div>
        <div>사업자등록번호</div> <div> 807-21-02141</div>
        <div>대표자성명</div>
        <div>구윤찬</div>
        <div>전자우편</div>
        <div>phenomenal312@hufs.gmail.com</div>
        <div>사업장소재지</div>
        <div>경기도 용인시 수지구 성복2로 10</div>
        <div>개업일</div>
        <div>2025/01/13</div>
        <div>사업자등록일</div>
        <div>2025/01/08</div>
        <div>업태</div>
        <div>예술, 스포츠 및 여가관련 서비스업</div>
        <div>종목</div>
        <div>그 외 기타 스포츠 서비스업</div>
      </div>
      <div className="my-5">
        <div className="my-5">
          본자료는 소비자가 정확한 사업자 정보를 가지고 안전한 거래를 할 수
          있도록 전국 시,군,구에 신고된 통신판매업자의 신원정보를
          전자상거래소비자보호법에 따라 제공하는 정보입니다. 사업자 정보에 대한
          궁금한 사항이나 사업자의 신원정보가 정보공개 내용과 불일치할 경우에는
          사업자 정보검색시 확인되는 해당 신고기관(지방자치단체)에 문의하여
          주시기 바랍니다.
        </div>
        일부 사업자의 경우, 부가가치세법상 사업자 폐업 신고는 하였으나
        전자상거래법상 통신판매업 폐업 신고는 하지 않은 사례가 있을 수 있습니다.
        소비자 피해를 방지하기 위해 부가가치세법상 사업자 폐업 여부도 국세청
        홈택스 페이지(www.hometax.go.kr)의 사업자등록상태조회 코너를 통해
        확인하시기 바랍니다.
      </div>
    </div>
  );
};

export default BeInfo;
