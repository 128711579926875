import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="text-white text-opacity-30 mb-[73px] px-10 text-center text-[12px]">
      WEKICK 대표 구윤찬 | 주소: 경기도 용인시 수지구 성복2로 10 <br />
      사업자등록번호: 807-21-02141
      <span
        onClick={() => navigate("/binfo")}
        className="border-b-2 border-white border-opacity-30 cursor-pointer"
      >
        등록정보확인
      </span>
      <br />
      호스팅서비스사업자: (주)카카오
    </div>
  );
};

export default Footer;
