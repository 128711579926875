import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navigation from "./components/Navigation/navigation";
import { useRecoilValue } from "recoil";
import { searchClickState } from "./atom";
import Footer from "./Footer";

export default function Layout() {
  const isClick = useRecoilValue(searchClickState);

  return (
    <div className="layout">
      <div className="px-5 mb-10">
        <Outlet />
      </div>
      <Footer />
      {isClick ? null : <Navigation />}
    </div>
  );
}
